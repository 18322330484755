import { gql, useQuery } from '@apollo/client'
import React, { createContext, useContext, useState } from 'react'

import { CartType } from '../../../types/Cart'

const defaultState: CartState = {
  setCart: () => {},
  cart: undefined,
}

const CartContext = createContext(defaultState)

//cart provider
const CartProvider: React.FC = ({ children }) => {
  // If we have a window and the cart already exists in local storage then initialize the cart value otherwise null.

  const [cart, setCart] = useState<CartState['cart']>(defaultState.cart)

  // A method for setting the cart in state

  useQuery(GET_CART, {
    onCompleted: ({ cart }) => {
      setCart(cart)
    },
    onError: () => {
      setCart(defaultState.cart)
    },
  })

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  )
}

const useCartState = () => useContext(CartContext)

type CartState = {
  cart: CartType | undefined
  setCart: (cart: CartType | undefined) => void
}

const GET_CART = gql`
  query GET_CART {
    cart {
      subtotal
      total
      shippingTotal
      contents {
        itemCount
        nodes {
          quantity
          key
          product {
            node {
              name
              databaseId
              ... on SimpleProduct {
                price
                salePrice
                regularPrice
              }
            }
          }
        }
      }
    }
  }
`

export { CartProvider, useCartState }
