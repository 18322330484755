import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/components/context/apolloClient'
import { CartProvider } from './src/components/context/CartContext'

export function wrapRootElement({ element }) {
  return (
    <ApolloProvider client={client}>
      <CartProvider>{element}</CartProvider>
    </ApolloProvider>
  )
}